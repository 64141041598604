import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "../components/others/NotFound";
import Login from "../pages/Login";
import SignupPage from "../pages/SignupPage";
import ScrollToTop from "./ScrollToTop";

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/create-account" element={<SignupPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
